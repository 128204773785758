$brand-color: #000;
$secondary-brand-color: #dda ; // #fa0; // #00aa00;
$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);
$background-color: $brand-color;

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";


@font-face {
  font-family: "ROBOTS";
  src: url("/assets/robots.eot"); /* IE9 Compat Modes */
  src: url("/assets/robots.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/robots.otf") format("opentype"), /* Open Type Font */
    url("/assets/robots.svg") format("svg"), /* Legacy iOS */
    url("/assets/robots.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/robots.woff") format("woff"), /* Modern Browsers */
    url("/assets/robots.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

span#robot {
    font-family: 'ROBOTS';
    font-size: 700%;
//    color: #ffaacc; // baby pink
//    color: #aaccff; // baby blue
    color: $secondary-brand-color;
//    font-weight:normal;
//    text-align: left;
//    line-height: 100%;
    line-height: 120%;
//    border-bottom: 1px solid #484848;
//    padding-bottom: 5px;
//    margin-bottom:7px;

//    &.small { font-size: 120px; }
//    &.medium { font-size: 170px; }
//    &.big { font-size: 220px; }
}

section.page { background-color: #ffffd1; }
p, .faq { color: #292929 !important; }
